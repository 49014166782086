import CustomImgTag from "components/shared/CustomImgTag";
import { investors } from "constant/investorsData";

const InvestorsSection = ({ showBackgroundColor = true }) => (
  <div
    className={`${
      showBackgroundColor ? "bg-[#F5F5F5]" : ""
    } grid grid-flow-row place-items-center rounded-xl gap-y-4 md:gap-y-0 grid-cols-6 grid-rows-2 md:grid-cols-5 md:grid-rows-1 justify-between items-center py-6 px-6`}
  >
    {investors.map((investor, id) => (
      <div key={`investor-${id}`} className={`${investor.imageCss} md:px-4`}>
        <CustomImgTag
          srcWeb={investor.logoWeb}
          altText={`investor-${id}`}
          webImgWidth={investor.imgWidthWeb}
          webImgHeight={investor.imgHeightWeb}
          mobileImgWidth={investor.imageWidthMobile}
          mobileImgHeight={investor.imageHeightMobile}
        />
      </div>
    ))}
  </div>
);

export default InvestorsSection;
