import Image from "next/image";
import withWindowDimensions from "./withWindowDimensions";
export default withWindowDimensions(
  ({
    srcWeb,
    srcMobile,
    altText = "",
    windowWidth,
    onClick = () => {},
    className,
    isMobileSized,
    webImgWidth,
    webImgHeight,
    mobileImgWidth,
    mobileImgHeight,
  }) => (
    <Image
      alt={altText}
      src={
        windowWidth > 1025 ? `${srcWeb}` : `${srcMobile ? srcMobile : srcWeb}`
      }
      onClick={onClick}
      className={className}
      width={(isMobileSized ? mobileImgWidth : webImgWidth) ?? webImgWidth}
      height={(isMobileSized ? mobileImgHeight : webImgHeight) ?? webImgHeight}
    />
  )
);
