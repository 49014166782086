import { imageBasePath } from "lib/utils/imageUtils";
export const investors = [
    {
      logoWeb: `${imageBasePath}/assets/images/about/investors/investor-1.png`,
      imgWidthWeb: 143,
      imgHeightWeb: 59,
      imageWidthMobile: 67,
      imageHeightMobile: 28,
      imageCss:
        "col-span-2 md:col-auto md:order-1 place-self-start sm:place-self-center",
    },
    {
      logoWeb: `${imageBasePath}/assets/images/about/investors/investor-2.png`,
      imgWidthWeb: 147,
      imgHeightWeb: 48,
      imageWidthMobile: 85,
      imageHeightMobile: 28,
      imageCss: "col-span-2 md:col-auto md:order-3",
    },
    {
      logoWeb: `${imageBasePath}/assets/images/about/investors/investor-3.png`,
      imgWidthWeb: 123,
      imgHeightWeb: 48,
      imageWidthMobile: 63,
      imageHeightMobile: 24,
      imageCss:
        "col-span-2 md:col-auto md:order-5 place-self-end sm:place-self-center",
    },
    {
      logoWeb: `${imageBasePath}/assets/images/about/investors/investor-4.png`,
      imgWidthWeb: 165,
      imgHeightWeb: 64,
      imageWidthMobile: 90,
      imageHeightMobile: 35,
      imageCss: "col-span-3 md:col-auto md:order-4",
    },
    {
      logoWeb: `${imageBasePath}/assets/images/about/investors/investor-5.png`,
      imgWidthWeb: 160,
      imgHeightWeb: 46,
      imageWidthMobile: 111,
      imageHeightMobile: 32,
      imageCss: "col-span-3 md:col-auto md:order-2",
    },
  ];